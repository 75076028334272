import { useStaticQuery, graphql } from "gatsby"

export const useData = () => {
  const data = useStaticQuery(
    graphql`
      query MyQuery {
        allContentfulInicio {
          edges {
            node {
              title
              node_locale
              subtitle {
                subtitle
              }
              buttonLeft
              buttonRight
              image {
                gatsbyImageData(placeholder: TRACED_SVG)
              }
              imageSecondary {
                gatsbyImageData(placeholder: TRACED_SVG)
              }
              imageTertiary {
                gatsbyImageData(placeholder: TRACED_SVG)
              }
            }
          }
        }
      }
    `
  )
  return data.allContentfulInicio.edges.map(i => ({
    title: i.node.title,
    lang: i.node.node_locale,
    subtitle: i.node.subtitle.subtitle,
    buttonLeft: i.node.buttonLeft,
    buttonRight: i.node.buttonRight,
    image: i.node.image.gatsbyImageData,
    imageSecondary: i.node.imageSecondary.gatsbyImageData,
    imageTertiary: i.node.imageTertiary.gatsbyImageData,
  }))
}
