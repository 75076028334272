import * as React from "react"

// import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Hero from "../components/sections/Hero"
import Seo from "../components/seo"

const IndexPage = () => (
  <>
    <Layout>
      <Seo title="Home" />
      <Hero />
    </Layout>
  </>
)

export default IndexPage
