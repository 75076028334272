import React, { useState } from "react"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

import { useData } from "../../utils/useHeroSection"
import { useLanguageContext } from "../../utils/LanguageContext"

const Hero = () => {
  const [display, setDisplay] = useState("hidden")
  const { toggle } = useLanguageContext()
  const hero = useData()
  const langFilteredEs = hero.filter(i => i.lang === "es")
  const langFilteredEn = hero.filter(i => i.lang === "en")
  const video = "https://youtu.be/K9EBjlAfHHE"

  return (
    <>
      <div className="flex justify-between px-4 pt-4">
        <div className="lg:w-1/2 flex flex-col items-center text-center lg:text-left lg:items-start mx-auto lg:mx-0">
          <h1 className="h1-mobile lg:hidden max-w-md lg:max-w-full">
            {toggle === "es"
              ? langFilteredEs.map(i => i.title)
              : langFilteredEn.map(i => i.title)}
          </h1>
          <h1 className="h1-desktop hidden lg:block lg:pt-8">
            {toggle === "es"
              ? langFilteredEs.map(i => i.title)
              : langFilteredEn.map(i => i.title)}
          </h1>
          <p className="py-4 max-w-md lg:max-w-full">
            {toggle === "es"
              ? langFilteredEs.map(i => i.subtitle)
              : langFilteredEn.map(i => i.subtitle)}
          </p>
          <div className="mt-4 mb-6 lg:flex inline whitespace-nowrap">
            <Link to="/quienes-somos" className="main-button lg:mr-4">
              {toggle === "es"
                ? langFilteredEs.map(i => i.buttonLeft)
                : langFilteredEn.map(i => i.buttonLeft)}
            </Link>
            <Link to="/clases" className="secondary-button mt-4 lg:mt-0">
              {toggle === "es"
                ? langFilteredEs.map(i => i.buttonRight)
                : langFilteredEn.map(i => i.buttonRight)}
            </Link>
          </div>
        </div>
        <div className="hidden lg:block ">
          <StaticImage
            src="../../images/hero.png"
            placeholder="tracedSVG"
            alt="calendar image"
          />
        </div>
      </div>

      <div className="space-y-8 lg:space-y-0">
        {langFilteredEs.map(i => (
          <>
            <GatsbyImage
              image={i.imageSecondary}
              alt="calendar image"
              className="mx-auto w-full lg:my-16"
            />
            <GatsbyImage
              image={i.imageTertiary}
              alt="calendar image"
              className="mx-auto w-full lg:my-16"
            />
          </>
        ))}
        <button onClick={() => setDisplay("block")}>
          {display === "hidden" && (
            <StaticImage
              height={800}
              src="../../images/videoPlaceHolderImageLow.png"
              placeholder="tracedSVG"
              alt="calendar image"
              className="lg:mt-16"
            />
          )}
        </button>
        {display === "block" && (
          <div className="bg-black">
            <iframe
              className="w-full h-[400px] lg:h-[800px] lg:mt-16"
              autoPlay
              src="https://www.youtube.com/embed/K9EBjlAfHHE?autoplay=1"
              allow="autoplay"
              frameborder="0"
              allowfullscreen
            ></iframe>
          </div>
        )}
      </div>
    </>
  )
}

export default Hero
